<template>
  <div>
    <b-form-group
      label="Nombre"
      label-for="input-name"
      label-cols="4"
      label-cols-sm="3"
      class="label"
    >
      <b-form-input
        id="input-name"
        name="input-name"
        v-model="$v.formation_line.name.$model"
        :state="validateState('name')"
        aria-describedby="input-name-live-feedback"
        size="sm"
        class="input-name"
      ></b-form-input>
      <b-form-invalid-feedback id="input-name-live-feedback"
        >Este campo debe tener al menos 5 caracteres.</b-form-invalid-feedback
      >
    </b-form-group>
    <b-form-group
      label="Descripción"
      label-for="input-description"
      label-cols="4"
      label-cols-sm="3"
      class="label"
    >
      <b-form-textarea
        id="input-description"
        name="input-description"
        v-model="$v.formation_line.description.$model"
        :state="validateState('description')"
        aria-describedby="input-description-live-feedback"
        size="md"
        rows="3"
        max-rows="8"
      ></b-form-textarea>
      <b-form-invalid-feedback id="input-description-live-feedback"
        >Este campo debe tener al menos 5 caracteres.</b-form-invalid-feedback
      >
    </b-form-group>
    <b-form-group
      :label="$getVisibleNames('mesh.faculty', true, 'Facultades') + ':'"
      label-for="input-faculties"
      label-cols="0"
      label-cols-sm="3"
      class="sm p-0 m-0"
    >
      <b-form-select
        id="input-faculties"
        v-model="$v.formation_line.faculties.$model"
        :options="facultiesList"
        value-field="id"
        text-field="name"
        :state="validateState('faculties')"
        aria-describedby="input-faculties-feedback"
        size="sm"
        multiple
        :select-size="facultiesList.length"
      ></b-form-select>
      <b-form-invalid-feedback id="input-faculties-feedback"
        >Debe agregar la
        {{ $getVisibleNames("mesh.faculty", false, "Facultades") }} de la
        {{
          $getVisibleNames("mesh.egressprofilematter", false, "Asignatura")
        }}.</b-form-invalid-feedback
      >
    </b-form-group>
    <b-form-group
      label="Color"
      label-for="input-color"
      label-cols="4"
      label-cols-sm="3"
      class="label"
    >
      <b-form-input
        type="color"
        id="input-color"
        name="input-color"
        v-model="$v.formation_line.color.$model"
        :state="validateState('color')"
        aria-describedby="input-color-live-feedback"
        size="md"
        rows="3"
        max-rows="8"
      >
      </b-form-input>
    </b-form-group>
    <b-form-group
      v-if="!isNaN(this.formation_line.id)"
      label-for="input-modification-date"
      label-cols="0"
      label-cols-sm="0"
      class="p-0 m-0 mt-2"
    >
      <ModificationDate :Information="formation_line"></ModificationDate>
    </b-form-group>
    <div class="row">
      <div
        v-if="show_delete_button && !isNaN(this.formation_line.id)"
        class="col"
        style="text-align: left"
      >
        <b-button
          class="mr-1"
          size="sm"
          variant="danger"
          @click="deleteFormationLine"
          >Eliminar</b-button
        >
      </div>
      <div v-if="show_save_button" class="col" style="text-align: right">
        <b-button class="m-0" size="sm" @click="save">Guardar</b-button>
      </div>
    </div>
  </div>
</template>

<script>
import { generateUniqueId, toast } from "@/utils/utils";
import { validationMixin } from "vuelidate";
import { required, minLength } from "vuelidate/lib/validators";
import * as names from "@/store/names";
import { mapGetters } from "vuex";
export default {
  name: "FormationLineForm",
  mixins: [validationMixin],
  components: {
    ModificationDate: () => import("@/components/reusable/ModificationDate"),
  },
  props: {
    FormationLine: {
      type: Object,
      default: function () {
        return {
          id: generateUniqueId(),
          name: "",
          career: null,
          faculties: [],
          description: "",
          color: "#14acb4",
          updated_by: null,
          update_date: null,
        };
      },
    },
    faculty_id: { default: null },
    show_save_button: {
      type: Boolean,
      default: true,
    },
    show_delete_button: {
      type: Boolean,
      default: false,
    },
    show_title: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      formation_line: { ...this.FormationLine },
    };
  },
  validations: {
    formation_line: {
      name: {
        required,
        minLength: minLength(5),
      },
      description: {},
      color: {},
      faculties: {
        isValid() {
          if (this.faculty_id != null) {
            if (this.formation_line.faculties.includes(this.faculty_id))
              return true;
            else return false;
          }
          return true;
        },
      },
    },
  },
  computed: {
    ...mapGetters({
      careers: names.CAREERS,
      facultiesList: names.FACULTIES,
    }),
  },
  methods: {
    validateState(name) {
      const { $dirty, $error } = this.$v.formation_line[name];
      return $dirty ? !$error : null;
    },
    save() {
      this.$v.formation_line.$touch();
      if (this.$v.formation_line.$anyError) {
        return;
      }
      if (isNaN(this.formation_line.id)) {
        this.createFormationLine();
      } else this.updateFormationLine();
    },
    createFormationLine() {
      this.$store
        .dispatch(names.POST_FORMATION_LINE, this.formation_line)
        .then((response) => {
          toast(
            String(
              this.$getVisibleNames(
                "mesh.formationline",
                false,
                "Línea De Formación"
              ) + " creada."
            )
          );
          this.$emit("created", response);
        });
    },
    updateFormationLine() {
      this.$store
        .dispatch(names.UPDATE_FORMATION_LINE, this.formation_line)
        .then((response) => {
          toast(
            String(
              this.$getVisibleNames(
                "mesh.formationline",
                false,
                "Línea De Formación"
              ) + " actualizada."
            )
          );
          this.$emit("updated", response);
        });
    },
    deleteFormationLine() {
      this.$swal({
        title: `¿Está seguro de que desea eliminar esta instancia de "${this.$getVisibleNames(
          "mesh.formationline",
          false,
          "Línea De Formación"
        )}"?`,
        text: "¡Esta acción no se podrá revertir!",
        type: "warning",
        showCancelButton: true,
      }).then((result) => {
        if (result.value) {
          this.$store.dispatch(
            names.DELETE_FORMATION_LINE,
            this.formation_line.id
          );
          this.$emit("deleted", this.formation_line);
        }
      });
    },
  },
};
</script>

<style scoped>
.label {
  margin-top: 1%;
}
.input-name {
  margin-top: -1px;
}
#input-color {
  cursor: pointer;
}
</style>